<template>
  <div class="layout-profile">
    <div>
      <img src="assets/layout/images/profile_logo.png" style="width:125px; height:35px; background-color: white;" alt="" />
    </div>
    <button class="p-link layout-profile-link" @click="onClick">
      <span class="UserName">{{ user.firstName }} {{ user.lastName }}</span
      ><br />
      <span class="position">({{ user.staffPosition }})</span>
      <!-- <i class="pi pi-fw pi-cog"></i> -->
    </button>
    <transition name="layout-submenu-wrapper">
      <ul v-show="expanded">
        <!-- <li><button class="p-link"><i class="pi pi-fw pi-user"></i><span>Account</span></button></li>
                <li><button class="p-link"><i class="pi pi-fw pi-inbox"></i><span>Notifications</span><span class="menuitem-badge">2</span></button></li> -->
        <li>
          <button class="p-link">
            <i class="pi pi-fw pi-power-off"></i
            ><span href="javascript:void(0)" @click="handleClick">Logout</span>
          </button>
        </li>
        <!-- <router-link to="/signin"
          ><i class="pi pi-fw pi-power-off"></i><span>Logout</span></router-link
        > -->
      </ul>
    </transition>
  </div>
</template>

<script>
export default {
  // props: ["user"],
  data() {
    return {
      expanded: false,
    };
  },
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
  },
  methods: {
    handleClick() {
      localStorage.removeItem("token");
      localStorage.removeItem("userId");
      localStorage.removeItem("userName");
      localStorage.removeItem("isStaff");
      localStorage.removeItem("firstName");
      localStorage.removeItem("lastName");
      localStorage.removeItem("staffPosition");
      localStorage.removeItem("counterId");
      localStorage.removeItem("counterGroupId");
      //     this.$store.dispatch('user',null);
      // console.log(localStorage.getItem("userName"));
      this.$store.dispatch("logout");
      this.$router.push("/login");
    },
    // logout: function () {
    //   this.$store.dispatch("logout").then(() => {
    //     this.$router.push("/login");
    //   });
    // },
    onClick(event) {
      this.expanded = !this.expanded;
      event.preventDefault();
    },
  },
};
</script>

<style scoped>
.position {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}
</style>
